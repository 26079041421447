import MonitorUtils, { MONITORING_COLLECTOR_TYPE } from "utils/MonitorUtils";

interface ITracking {
  sku: string
  sellerId: string
  productId: string
  type: string
  pathname: string
  sellerIdMainSKU: string
  productIdMainSKU: string
  mainSKU: string
  mainType: string
}

const mapPage: { [key: string]: string } = {
  products: 'product-list',
  'product/[slug]': 'product-detail-page',
  'quick-order': 'quick-order',
  cart: 'cart',
  'substitutes': 'substitute-products-listing'
}

export enum EnumSubstituteTrackingActionType {
    CLICK = 'CLICK',
    HOVER = 'HOVER',
    ADD_CHANGE_QUANTITY = 'ADD_CHANGE_QUANTITY',
}

const useTrackingMonitorSubstituteProduct = () => {
  const trackingMonitorSubstituteMainSKUImpression = ({sku, sellerId, productId, type, pathname, sellerIdMainSKU, productIdMainSKU, mainSKU, mainType}: ITracking) => {
    const event = MONITORING_COLLECTOR_TYPE.SUBSTITUTE_PRODUCT_MAIN_SKU_IMPRESSION;
    const metadata = {
      sku,
      sellerId,
      productId,
      type: mainType,
      screen: mapPage?.[pathname.replace("/", "")],
      platform: 'web',
      sellerIdMainSKU,
      productIdMainSKU,
      mainSKU
    }
    MonitorUtils.substituteProductClickEvent(event, metadata);
  }
  const trackingMonitorSubstituteClickDetail = ({sku, sellerId, productId, type, pathname, sellerIdMainSKU, productIdMainSKU, mainSKU, mainType}: ITracking) => {
    const event = MONITORING_COLLECTOR_TYPE.SUBSTITUTE_PRODUCT_CLICK_DETAIL;
    const metadata = {
      sku,
      sellerId,
      productId,
      type: mainType,
      screen: mapPage?.[pathname.replace("/", "")],
      platform: 'web',
      sellerIdMainSKU,
      productIdMainSKU,
      mainSKU
    }
    MonitorUtils.substituteProductClickEvent(event, metadata);
  }
  
  const trackingMonitorSubstituteAddToCart = ({sku, sellerId, productId, type, pathname, sellerIdMainSKU, productIdMainSKU, mainSKU, mainType}: ITracking) => {
    const event = MONITORING_COLLECTOR_TYPE.SUBSTITUTE_PRODUCTS_ADD_TO_CART;
    const metadata = {
      sku,
      sellerId,
      productId,
      type: mainType,
      screen: mapPage?.[pathname.replace("/", "")],
      platform: 'web',
      sellerIdMainSKU,
      productIdMainSKU,
      mainSKU
    }
    MonitorUtils.substituteProductClickEvent(event, metadata);
  }

  const trackingMonitorSubstituteClickMore = ({sku, sellerId, productId, type, pathname, sellerIdMainSKU, productIdMainSKU, mainSKU, mainType}: ITracking) => {
    const event = MONITORING_COLLECTOR_TYPE.SUBSTITUTE_PRODUCTS_CLICK_MORE;
    const metadata = {
      sku,
      sellerId,
      productId,
      type: mainType,
      screen: mapPage?.[pathname.replace("/", "")],
      platform: 'web',
      sellerIdMainSKU,
      productIdMainSKU,
      mainSKU
    }
    MonitorUtils.substituteProductClickEvent(event, metadata);
  }

  return {
    trackingMonitorSubstituteMainSKUImpression,
    trackingMonitorSubstituteClickDetail,
    trackingMonitorSubstituteAddToCart,
    trackingMonitorSubstituteClickMore,
  }
}


export default useTrackingMonitorSubstituteProduct