export const renderVoucherText = (label: string, params: Record<string, string>): string => {
    if (!label) return ``;
    // get list param with pattern: {{param}}
    const words = label.match(/{{(.*?)}}/g) || [];

    for (const param of words) {
      const dtoParam = param.replace(/\W|đ/g, '');
      label = label.replace(param, params[dtoParam]);
    }

    return label;
};