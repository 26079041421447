import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { tooltipClasses } from '@mui/material/Tooltip';
import { isValid, isValidWithoutData } from 'clients/Clients';
import { deleteItemWishlist, updateWishlist } from 'clients/WishlistClient';
import clsx from 'clsx';
import LinkComp from 'components/atoms/LinkComp';
import { HTTP_STATUS } from 'constants/Enums/http';
import { COLOR_STORE } from 'constants/Icons';
import { SUBSTITUDE_ARROW_RIGHT_ICON, SUBSTITUDE_REPLACE_ICON, SUBSTITUTE_RECOMMEND_ICON } from 'constants/Images';
import { SUBSTITUTES } from 'constants/Paths';
import { SVGPrice } from 'constants/SVG';
import useTrackingMonitor from 'hooks/useTrackingMonitor';
import useTrackingMonitorSubstituteProduct from 'hooks/useTrackingMonitorSubstituteProduct';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { DOMAIN_WEB_HOST } from 'sysconfig';
import { EnumHighProfitLayer, ProductDataLiteVertical, SkuType, TagLabelQuickOrder } from 'types/product';
import { formatCurrency } from 'utils/FormatNumber';
import gtag from 'utils/gtag';
import { encryptedData } from 'utils/helper';
import { ImageFallbackProductImage, ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxyFile } from 'utils/ImageUtils';
import MonitorUtils, { MONITORING_COLLECTOR_TYPE } from 'utils/MonitorUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { useStore } from 'zustand-lib/storeGlobal';
import useProductListStates from 'zustand-lib/useProductList';
import useSellers from 'zustand-lib/useSellers';
import BorderLinearProgress from '../BorderLinearProgress';
import CountdownNewV2 from '../CountdownNewV2';
import QuantityControlLite from '../QuantityControlLite';
import styles from './styles.module.css';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#A2A0A0',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#A2A0A0',
    color: '#fff',
    fontSize: 12,
    top: '0px',
  },
}));

/**
 * Based on src/components-v2/mocules/ProductCardNewList/index.js
 * Use new product data format (example api: /recommendation/product-lite), which returns all necessary data for disp
lay.
 */
type ProductCardLiteProps = {
  product: ProductDataLiteVertical;
  className?: string;
  id?: string;

  /** ID of the substitute SKU */
  skuSubstitute?: string;

  /** Event handler when clicking on card */
  onClickCard: (e: Event) => void;

  /** Event handler when clicking minus button in qty */
  onMinus: (e: Event) => void;

  /** Event handler when changing input in qty */
  onChange: (e: Event) => void;

  /** Event handler when clicking plus button in qty */
  onAdd: (e: Event) => void;
};

export default function ProductCardLite({ product, className, id, skuSubstitute, onClickCard, onMinus, onChange, onAdd }: ProductCardLiteProps) {
  const {
    dealEndTime,
    dealSoldPercent,
    dealText,
    discountPercent,
    discountPriceEncrypted,
    errorMessage,
    hasContract,
    imageUrl,
    isSKUReplace,
    labels,
    limitMessage,
    nearExpirationLabel = '',
    priceEncrypted,
    productName,
    sellerName,
    sellerUrl,
    skuCode,
    skuReplace = [],
    slug,
    tags,
    type,
    volume,
    isHighProfit = false,
    styleDisplay,
  } = product;
  const router = useRouter();

  const [isWishlist, setIsWishlist] = useState(false);
  const { firstSubstituteSkuRef } = useProductListStates();
  const { trackingMonitorSubstituteMainSKUImpression } = useTrackingMonitorSubstituteProduct();
  const linkHref = isSKUReplace
    ? `${DOMAIN_WEB_HOST}/product/${slug || ''}?isSKUReplace=true&sellerIdMainSKU=${product?.dataParent?.sellerId}&productIdMainSKU=${product?.dataParent?.productId}&mainSKU=${product?.dataParent?.skuCode}&mainType=${product?.dataParent?.errorCode || 'NORMAL'}`
    : `${DOMAIN_WEB_HOST}/product/${slug || ''}`;
  // high profit product
  const [headColor, middleColor, endColor] = styleDisplay?.web?.background?.colors || [];
  const [headPercentColor, middlePercentColor, endPercentColor] = styleDisplay?.web?.background?.percentsColor || [0, 0.5, 1];
  const angle = styleDisplay?.web?.background?.angle || 180;

  const trackingRef = useRef(null);
  useTrackingMonitor(trackingRef, product);

  useEffect(() => {
    setIsWishlist(product.isWishlist);
  }, [product.isWishlist]);

  const getSellerByCode = useSellers((state) => state.getSellerByCode);
  const sendEventCollector = useStore((state) => state.sendEventCollector);
  async function handleTrackingViewDetail() {
    const sellerInfo = await getSellerByCode({ code: product?.sellerCode, tags: product.tags });
    gtag.viewItemInPage({ product: { ...product, sellerInfo }, path: router.pathname });
    sendEventCollector(MonitorUtils.sendSKUEvent(MONITORING_COLLECTOR_TYPE.SKU_DETAIL_VIEW, { ...product, sellerInfo }, router.pathname, true));
  }

  const revertDisplayFormat = (price: string | number) => {
    if (price?.length > 11) {
      return price?.substring(0, 8) + '..';
    }
    return price;
  };

  const handleDeleteProductWishlist = async () => {
    const res = await deleteItemWishlist(skuCode);
    if (isValidWithoutData(res)) {
      setIsWishlist(() => false);
      return NotifyUtils.success(`Đã xóa ${productName} khỏi danh sách quan tâm`);
    }
    return NotifyUtils.error(`Xóa sản phẩm ${productName} khỏi danh sách quan tâm thất bại`);
  };

  const handleUpdateWishlist = async () => {
    const res = await updateWishlist(skuCode);
    if (isValid(res) || res?.status === HTTP_STATUS.Existed) {
      setIsWishlist(() => true);
      return NotifyUtils.success(`Đã thêm ${productName} vào danh sách quan tâm`);
    }
    return NotifyUtils.error(`Thêm ${productName} vào danh sách sản phẩm quan tâm thất bại`);
  };

  const handleClickWishlist = async () => {
    if (isWishlist) {
      handleDeleteProductWishlist();
    } else {
      handleUpdateWishlist();
    }
  };

  const hasError = !!(errorMessage || product.salePrice === 0);
  const showSubstituteForInStock = !hasError && product.skuReplace?.length > 0;
  const showSubstituteForOOS = hasError && product.skuReplace?.length > 0;

  const conditionRenderTag = (listTag: TagLabelQuickOrder[]) => {
    if (listTag && listTag?.length > 3) {
      return listTag?.filter((x) => x?.code !== 'NEAR_EXPIRATION').slice(0, 3);
    }
    return listTag?.filter((x) => x?.code !== 'NEAR_EXPIRATION');
  };

  const trackingMonitorClient = ({ url, reffererUrl = router.asPath }: { url: string; reffererUrl?: string }) => {
    MonitorUtils.sendPageEvent({ url, reffererUrl });
  };

  return (
    <div
      className={clsx(
        styles.container, className,
        { ['highProfitDoubleLayer']: isHighProfit && styleDisplay?.web?.background?.colors?.length === EnumHighProfitLayer.DOUBLE_LAYER },
        { ['highProfitTribleLayer']: isHighProfit && styleDisplay?.web?.background?.colors?.length === EnumHighProfitLayer.TRIBLE_LAYER },
      )}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => onClickCard?.(e)}
      role="button"
      tabIndex={0}
      id={id}
      ref={skuSubstitute && skuSubstitute === id ? firstSubstituteSkuRef : trackingRef}
      onMouseEnter={() => {
        if (product?.isSKUReplace) {
          trackingMonitorSubstituteMainSKUImpression({
            sku: product?.skuCode,
            productId: product?.productId.toString() || '',
            sellerId: product?.sellerId.toString() || '',
            type: product?.errorCode || 'Instock',
            pathname: router.pathname,
            sellerIdMainSKU: product?.dataParent?.sellerId.toString() || '',
            productIdMainSKU: product?.dataParent?.productId.toString() || '',
            mainSKU: product?.dataParent?.skuCode?.toString() || '',
            mainType: product?.dataParent?.errorCode || 'NORMAL',
          });
        }
      }}
      style={
        {
          '--high-profit-head-color': headColor ?? undefined,
          '--high-profit-middle-color': middleColor ?? undefined,
          '--high-profit-end-color': endColor ?? undefined,
          '--high-profit-angle': angle ? `${angle}deg` : undefined,
          '--high-profit-head-percent-color': headPercentColor !== undefined ? `${headPercentColor * 100}%` : undefined,
          '--high-profit-middle-percent-color': middlePercentColor !== undefined ? `${middlePercentColor * 100}%` : undefined,
          '--high-profit-end-percent-color': endPercentColor !== undefined ? `${endPercentColor * 100}%` : undefined,
        } as React.CSSProperties
      }
    >
      {discountPercent && !isSKUReplace ? <Typography className={styles.promo}> -{discountPercent}%</Typography> : null}

      {hasContract && !isSKUReplace ? <Typography className={styles.contract}> Giá Hợp Đồng</Typography> : null}

      {nearExpirationLabel && nearExpirationLabel !== '' && !isSKUReplace ? (
        <Typography className={styles.nearExpiration}>{nearExpirationLabel}</Typography>
      ) : null}

      {isSKUReplace ? (
        <Box className={styles.replaceSkuTag}>
          <ImageFallbackStatic src={SUBSTITUDE_REPLACE_ICON} width={16} height={16} alt="replace icon" /> Sản phẩm thay thế
        </Box>
      ) : null}

      <div className={styles.tooltipRight} onClick={handleClickWishlist} role="button">
        <LightTooltip title={isWishlist ? 'Bỏ đánh dấu sản phẩm' : 'Đánh dấu sản phẩm quan tâm'} arrow placement="top">
          <div className={styles?.customButton}>
            {isWishlist ? (
              <FavoriteIcon style={{ fontSize: 24, color: '#00b46e' }} />
            ) : (
              <FavoriteBorderIcon className={styles.icon} style={{ fontSize: 24, color: '#00000033' }} />
            )}
          </div>
        </LightTooltip>
      </div>

      <div className={styles.topContainer}>
        <LinkComp href={linkHref} className={styles.stylesImage}>
          <ImageFallbackProductImage
            className={styles.image}
            height={160}
            width={160}
            objectFit="contain"
            fallbackSrc={imageUrl}
            src={imageUrl}
            alt="img"
            onClick={handleTrackingViewDetail}
          />
        </LinkComp>

        {showSubstituteForInStock ? (
          <ShowSubstituteCard isInStock skuReplace={skuReplace} />
        ) : (
          <div className={styles.listTag}>{conditionRenderTag(tags)?.map((x) => <ProductTagNew key={x?.code} data={x} />)}</div>
        )}
      </div>

      <div className={styles.underContainer}>
        <div className={styles.priceContainer}>
          <div className={styles.price}>
            <SVGPrice
              displayPriceFormated={formatCurrency(encryptedData(discountPriceEncrypted || priceEncrypted)) || 0}
              size="mdn"
              fillPath="#09884D"
            />
          </div>
          {discountPercent > 0 && (
            <>
              {!dealEndTime ? (
                <div className={styles.pricePromo}>
                  <SVGPrice displayPriceFormated={revertDisplayFormat(encryptedData(priceEncrypted)) ?? 0} size="smn" fillPath="#333333" line />
                </div>
              ) : (
                <div className={clsx(styles.dateExpired)}>
                  <CountdownNewV2 targetDate={dealEndTime} hasMoreText />
                </div>
              )}
            </>
          )}
        </div>

        <div className={styles.titleContainer}>
          <Link href={linkHref} passHref>
            <a className={styles.titleWrapper} onClick={handleTrackingViewDetail}>
              {labels?.map((label) => (
                <div key={label?.code} className={styles.labelImage}>
                  <ImageFallbackStatic
                    width={label.code === '2ABC' ? 63 : 25}
                    height={18}
                    objectFit="contain"
                    fallbackSrc={getLinkProxyFile(label?.iconUrl)}
                    src={getLinkProxyFile(label?.iconUrl)}
                    alt="img"
                  />
                </div>
              ))}
              <span>{productName}</span>
            </a>
          </Link>
          <Typography className={styles.countPerItem}>{volume || ''}</Typography>
        </div>

        {!!sellerName && (
          <Tooltip title={sellerName || ''} placement="bottom">
            <div className={styles.wrapperTooltip}>
              <div className={styles.wrapperImage}>
                <ImageFallbackStatic width="14" height="16" src={COLOR_STORE} alt={sellerName} />
              </div>
              <LinkComp
                stopProp
                target="_blank"
                className={clsx(styles.link)}
                href={sellerUrl}
                onClick={() => {
                  trackingMonitorClient({ url: sellerUrl });
                }}
              >
                {sellerName}
              </LinkComp>
            </div>
          </Tooltip>
        )}

        {!hasError && (
          <div className={styles.progressContainer}>
            {!!dealText && (
              <div className={styles.progressBuy}>
                {type !== SkuType.NORMAL && (
                  <>
                    <BorderLinearProgress
                      value={dealSoldPercent ?? 5}
                      variant="determinate"
                      width="100%"
                      height="14px !important"
                      bgcolor="#F9BDC0"
                      bgliner="#EA202B"
                      linerradius={0}
                    />
                    <Typography className={styles.buyCount}>{dealText}</Typography>
                  </>
                )}
              </div>
            )}
            <span className={styles.limitMessage}>{limitMessage}</span>
          </div>
        )}

        <div className={styles.controlQuantity}>
          {hasError && <Typography className={styles.error}>{errorMessage || 'Đang cập nhật giá'}</Typography>}
          {showSubstituteForOOS && <ShowSubstituteCard skuReplace={skuReplace} />}
        </div>
      </div>

      {!hasError && (
        <QuantityControlLite
          className={styles.quantityControlContainer}
          product={product}
          newCard
          onMinus={onMinus}
          onChange={onChange}
          onAdd={onAdd}
          blockCode={(router?.query?.blockCode as string) || ''}
        />
      )}
    </div>
  );
}

function ProductTagNew({ data }: { data: TagLabelQuickOrder }) {
  return data?.iconUrl ? (
    // check if url is object or string
    <LinkComp href={typeof data.url == "string" ? data.url : data.url?.pathname} className={styles.tagContainer}>
      <ImageFallbackStatic
        className={styles.image}
        height={30}
        width={60}
        objectFit="contain"
        fallbackSrc={getLinkProxyFile(data?.iconUrl)}
        src={getLinkProxyFile(data?.iconUrl)}
        alt="img"
      />
    </LinkComp>
  ) : (
    <></>
  );
}

function ShowSubstituteCard({ isInStock = false, skuReplace = [] }) {
  const router = useRouter();
  const [isShowing, setIsShowing] = useState(false);

  if (skuReplace?.length <= 0) {
    return <></>;
  }

  const handleClick = () => {
    if (isShowing) return;

    let listSkuReplaceBlock;
    if (router.pathname === SUBSTITUTES) {
      listSkuReplaceBlock = skuReplace.map((item) => item?.sku?.sku);
    } else {
      listSkuReplaceBlock = skuReplace.map((item) => item?.skuCode);
    }
    const queryAllSkuReplaceBlock = listSkuReplaceBlock?.map((item) => document.getElementById(item));
    if (queryAllSkuReplaceBlock?.length > 0) {
      queryAllSkuReplaceBlock?.forEach((item) => {
        item?.classList.toggle(styles.glowOnEffect);
        setIsShowing(true);

        setTimeout(() => {
          item?.classList.remove(styles.glowOnEffect);
          setIsShowing(false);
        }, 5000);
      });
    }
  };

  if (isInStock) {
    return (
      <div className={styles.showSubstitutesForInStock} onClick={handleClick}>
        <Box display="flex" alignItems="center" gridGap={4}>
          <ImageFallbackStatic src={SUBSTITUTE_RECOMMEND_ICON} width={21} height={21} />

          <span>Xem sản phẩm thay thế</span>

          <ImageFallbackStatic src={SUBSTITUDE_ARROW_RIGHT_ICON} width={16} height={16} alt="arrow right icon" />
        </Box>
      </div>
    );
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" padding="0 8px" alignItems="center">
      <Button className={styles.buttonRedirectReplaceSku} onClick={handleClick}>
        Xem sản phẩm thay thế
        <ImageFallbackStatic src={SUBSTITUDE_ARROW_RIGHT_ICON} width={16} height={16} alt="arrow right icon" />
      </Button>
    </Box>
  );
}
