/* eslint-disable react-hooks/rules-of-hooks */
import { RefObject, createRef } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface ProductListStore {
  firstSubstituteSkuRef: RefObject<HTMLDivElement>;
  triggerTour: number;
  substituteIndex: number | null;
  isHaveRcmProducts?: boolean;
  isRcmProductList?: boolean;
}

interface ISetting {
  [key: string]: {
    [key: string]: string | any;
  };
}

interface IUser {
  provinceCode: string;
  customerID: string;
}

interface Actions {
  updateTriggerTour: (payload: number) => void;
  updateSubstituteIndex: (payload: number | null) => void;
  setIsHaveRcmProducts: (setting: ISetting, user: IUser) => void;
  setIsRcmProductList: (payload: boolean) => void;
}

type IniticalProductListState = ProductListStore;
type ProductListState = IniticalProductListState & { actions: Actions };

const INITIAL_STATE: IniticalProductListState = {
  firstSubstituteSkuRef: createRef<HTMLDivElement>(),
  triggerTour: 0,
  substituteIndex: null,
  isHaveRcmProducts: false,
};

const useProductListStates = create<ProductListState>()(
  devtools((set, get) => ({
    ...INITIAL_STATE,
    actions: {
      updateTriggerTour: () => {
        const { triggerTour } = get();
        set({ triggerTour: triggerTour + 1 });
      },
      updateSubstituteIndex: (payload: number | null) => {
        set({ substituteIndex: payload });
      },
      setIsHaveRcmProducts: (setting, user) => {
        const KEY_RCM_LIST = 'RCM_PRODUCT_LIST';
        const KEY_AB_TESTING_CONFIG = 'AB_TESTING_CONFIG';
        const targetSource = 'PRODUCT_RCM';

        const { provinceCode, customerID } = user || {};
        const rcmProvinces = setting?.[KEY_RCM_LIST]?.provinceCodes;
        const isActiveABTesting = setting?.[KEY_AB_TESTING_CONFIG]?.isActive ?? false;
        const groupHaveRcm =
          Object.entries(setting?.[KEY_AB_TESTING_CONFIG] || {})
            .filter(([_, value]) => value.source === targetSource)
            ?.map(([_, value]) => value) || [];
        const combinedCustomerIds = groupHaveRcm?.reduce((acc, curr) => [...acc, ...curr.customerIDs], []);
        const isValidProvince = rcmProvinces?.includes(provinceCode) ?? false;
        const isInRcmConfig = isActiveABTesting && combinedCustomerIds?.includes(customerID);
        // isHaveRcmProducts: isValidProvince || isInRcmConfig,
        set({
          isHaveRcmProducts: isInRcmConfig,
        });
      },
      setIsRcmProductList: (payload: boolean) => {
        set({ isRcmProductList: payload });
      },
    },
  })),
);

export const userProductListActions = () => useProductListStates((state) => state.actions);

export default useProductListStates;
