import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(
  ({
    width,
    height,
    radius = 20,
    // Use lowercase to avoid warning "Warning: React does not recognize the `bgLiner` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `bgliner` instead. If you accidentally passed it from a parent component, remove it from the DOM element"
    bgcolor,
    bgliner,
    linerradius = 20,
  }) => ({
    height,
    width,
    borderRadius: radius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: bgcolor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: linerradius,
      backgroundColor: bgliner,
    },
  }),
);
export default BorderLinearProgress;
