import { useCart } from 'context/Cart/CartContext';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Product } from 'types/product';
import { debounceFunc400 } from 'utils/debounce';
import { ITrackingMetadata, UPDATE_CART, UpdateCart } from 'zustand-lib/InterfaceZustand';
import useCartStates, { useCartActions } from 'zustand-lib/useCart';
import { renderVoucherText } from '../Cart/CartItem/SuggestVoucher/utils';

const REGEX_NUMBER = new RegExp('^[0-9]+$');

type ProductCart = Pick<
  Product,
  | 'id'
  | 'name'
  | 'price'
  | 'sellerCode'
  | 'sellerId'
  | 'quantityInCart'
  | 'skuCode'
  | 'type'
  | 'slug'
  | 'maxQuantity'
  | 'eventSource'
  | 'recommendSKUs'
  | 'metric'
  | 'last_quantity'
  | 'current_quantity'
  | 'quantity'
  | 'content'
  | 'blockCode'
  | 'isHighProfit'
> & {
  trackingMetadata?: { [key: string]: string } & ITrackingMetadata;
};

type QuantityType = 'normal' | 'cart';

function useCartQuantityControl(product: ProductCart, inputRef: RefObject<HTMLInputElement>, type: QuantityType = 'cart') {
  const [preValue, setPreValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [isShowModalRemove, setIsShowModalRemove] = useState(false);
  const defaultValue = type === 'cart' ? '1' : '0';

  const { cartData, lastUpdatedIDRef, voucherSuggestions } = useCartStates();
  const { cartItems } = useCart();
  const { updateCartItem, removeCartItem, getIDRef, getSKUfromIDRef } = useCartActions();

  const voucherHelper = voucherSuggestions?.[product?.sellerCode] || null;

  const { current: idRef } = useRef(getIDRef(product));

  // Ensure that the input value is always in sync with the cartData
  // Use case: When there are many cards of the same product in the same page, and the user changes the quantity of one card, the quantity of the other cards should also be updated
  useEffect(
    function syncQuantity() {
      if (idRef === lastUpdatedIDRef) return; // Don't update itself
      if (getSKUfromIDRef(idRef) !== product.skuCode) return; // Don't update if this is not its product

      let quantityInCart = product.quantityInCart || 0;
      if ((cartData || []).length > 0) {
        const productInCart = cartData
          .map((group) => group.products)
          .flat()
          .find((p) => p.skuCode === product.skuCode);
        quantityInCart = productInCart?.quantityInCart || 0;
      } else if ((cartItems || []).length > 0) {
        const productInCart = cartItems.find((p) => p.sku === product.skuCode);
        quantityInCart = productInCart?.quantity || 0;
      }

      // HACK: use setTimeout to change the input value in the next tick
      setTimeout(() => {
        if (inputRef.current && +inputRef.current.value !== quantityInCart) {
          inputRef.current.value = String(quantityInCart);
          setCurrentValue(quantityInCart);
        }
      }, 0);
    },
    [cartData, cartItems],
  );

  const handleCart = (val: number | string, type: UpdateCart) => {
    const trackingForVoucherSuggestion = voucherHelper
      ? {
          last_quantity: String(product.quantityInCart),
          current_quantity: String(+val),
          sku: product.skuCode,
          content: 'Add SKU ở trang cart sau khi gợi ý voucher xuất hiện',
          voucher_code: voucherHelper?.param?.voucherCode,
          display_text: renderVoucherText(voucherHelper?.label, voucherHelper?.param),
        }
      : null;

    const params = {
      idRef,
      newQuantity: +val,
      product: {
        sku: product.skuCode,
        type: product.type,
        name: product.name,
        productId: product.id,
        sellerCode: product.sellerCode,
        sellerID: product.sellerId,
        eventSource: product.eventSource,
        metric: product.metric,
        recommendSKUs: product.recommendSKUs,
        metadata: {
          ...trackingForVoucherSuggestion,
          ...(product.trackingMetadata || {}),
        },
        blockCode: product?.blockCode,
        isDeal: false,
        price: product.price,
        isHighProfit: product.isHighProfit,
        ...(type === UPDATE_CART.REMOVE && { slug: product.slug, salePrice: '', cartItemType: '' }),
      },
    };

    switch (type) {
      case UPDATE_CART.INCREASE:
        return updateCartItem({ type: UPDATE_CART.INCREASE, ...params });
      case UPDATE_CART.DECREASE:
        return updateCartItem({ type: UPDATE_CART.DECREASE, ...params });
      case UPDATE_CART.REMOVE:
        return removeCartItem({ type: UPDATE_CART.REMOVE, ...params });
    }
  };

  const handler = (val: number | string, updateType: UpdateCart) => {
    debounceFunc400(() => {
      handleCart(val, updateType);
    });
  };

  const confirmRemove = () => {
    if (inputRef.current) inputRef.current.value = '0';
    setIsShowModalRemove(false);
    return handler(0, UPDATE_CART.REMOVE);
  };

  const closeModal = () => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
    }
    if (type === 'cart') {
      setPreValue(0);
      setCurrentValue(1);
    }
    setIsShowModalRemove(false);

    return handler(defaultValue, UPDATE_CART.INCREASE);
  };

  const handleDecrease = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (inputRef.current) {
      const newValue = +inputRef.current.value - 1;

      if (newValue >= 0) {
        if (newValue === 0) {
          return setIsShowModalRemove(true);
        }
        inputRef.current.value = String(newValue);
        setPreValue(currentValue);
        setCurrentValue(newValue);
        return handler(newValue, UPDATE_CART.DECREASE);
      }
      return null;
    }
    return null;
  };

  const handleIncrease = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (inputRef.current) {
      const newValue = +inputRef.current.value + 1;

      if (product.maxQuantity > 0 && newValue > product.maxQuantity) {
        return null;
      }
      setPreValue(currentValue);
      setCurrentValue(newValue);
      inputRef.current.value = String(newValue);

      return handler(newValue, UPDATE_CART.INCREASE);
    }

    return null;
  };

  const handleBlurInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (+value !== +preValue) {
      if (+value && REGEX_NUMBER.test(value)) {
        return handler(value, +value > +preValue ? UPDATE_CART.INCREASE : UPDATE_CART.DECREASE);
      }
      if (+value === 0) {
        return setIsShowModalRemove(true);
      }
    }
    return null;
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!inputRef.current) return null;

    if (value) {
      let newValue = parseInt(value, 10);

      // Check if newValue exceeds maxQuantity or 10000
      if ((product.maxQuantity > 0 && newValue > product.maxQuantity) || newValue > 10000) {
        newValue = product.maxQuantity > 0 ? product.maxQuantity : 10000;
      }

      // Validate input value using REGEX_NUMBER
      if (!REGEX_NUMBER.test(String(newValue))) {
        newValue = 0;
      }

      // Update the input value and state
      inputRef.current.value = String(newValue);
      setPreValue(parseInt(value, 10) > product.maxQuantity ? product.maxQuantity - 1 : currentValue);
      setCurrentValue(newValue);
    } else {
      inputRef.current.value = '0';
    }
  };

  useEffect(() => {
    const productQuantity = product.quantityInCart || 0;
    setPreValue(productQuantity);
    setCurrentValue(productQuantity);
    if (inputRef.current) inputRef.current.value = String(productQuantity);
  }, [product.quantityInCart]);

  return {
    handleIncrease,
    handleBlurInput,
    handleChangeInput,
    handleDecrease,
    confirmRemove,
    closeModal,
    isShowModalRemove,
    currentValue,
  };
}

export default useCartQuantityControl;
